<template>
  <div class="mainwrap pushedit" v-loading="loading">
    <div class="title">通知訊息-編輯</div>
    <div class="formwrap">
      <el-form
        :model="pushForm"
        :rules="rules"
        ref="pushForm"
        label-width="150px"
        label-position="left"
      >
        <el-form-item label="標題" prop="title">
          <el-input
            v-model="pushForm.title"
            placeholder="請輸入標題"
          ></el-input>
        </el-form-item>

        <el-form-item label="標簽" prop="subTitle">
          <el-input
            v-model="pushForm.subTitle"
            placeholder="請輸入標簽"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否推播優惠券" prop="couponDirectPush">
          <el-radio-group v-model="pushForm.couponDirectPush" style="width: 650px" @change="changeCouponDirect">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="推送時間" prop="startDateValue" v-if="isPushNotification">
          <div class="startDateTimeBox">
            <el-date-picker
              v-model="pushForm.startDateValue"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="選擇推送日期"
              :picker-options="pickerOptions"
              @change="changeDate"
            >
            </el-date-picker>
            <!-- <el-select v-model="pushForm.startTimeValue" placeholder="請選擇">
              <el-option
                v-for="item in timeArr"
                :key="item.value"
                :label="item.value"
                :value="item.value"
                :disabled="item.isdisabled"
                >
              </el-option>
            </el-select> -->
            <el-time-picker
              v-model="pushForm.startTimeValue"
              value-format="HH:mm:ss"
              format="HH:mm"
              :picker-options="pickerTimeOptions"
              >
            </el-time-picker>
          </div>
        </el-form-item>

        <el-form-item label="鏈接" prop="pushType"  v-if="isPushNotification">
          <el-radio-group v-model="pushForm.pushType" style="width: 650px" @change="changePushType">
            <el-radio :label="1">餐廳資訊</el-radio>
            <el-radio :label="2">最新消息</el-radio>
            <el-radio :label="3">通知信息</el-radio>
            <el-radio :label="4">優惠券</el-radio>
            <el-radio :label="5">外鏈</el-radio>
          </el-radio-group>

          <div
            v-if="
              pushForm.pushType !== '' &&
              pushForm.pushType !== 3 &&
              pushForm.pushType !== 5
            "
            class="inlineblock"
          >
            <el-input disabled v-model="pushForm.linkName"></el-input>
            <el-button type="primary" @click="openLinkVisible">選擇</el-button>
          </div>
          <div v-if="pushForm.pushType === 5">
            <el-input
              v-model="pushForm.targetValue"
              placeholder="請輸入跳轉鏈接，https://或http://開頭"
            ></el-input>
          </div>
        </el-form-item>

        <el-form-item label="列表圖片" prop="listImageUrl">
          <el-upload
            class="img-uploader"
            action=""
            :auto-upload="false"
            :show-file-list="false"
            :on-change="handleChange"
          >
            <img
              v-if="pushForm.listImageUrl"
              :src="pushForm.listImageUrl"
              class="imgbox"
            />
            <i v-else class="el-icon-plus"></i>
            <div
              @click.stop.prevent
              v-if="pushForm.listImageUrl"
              class="deletewrap"
            >
              <i class="el-icon-delete" @click="handleRemove"></i>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上傳jpg/png文件，且不超過2MB，圖片比例 4:3
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="詳情圖片"
          prop="detailImageUrls"
          v-if="pushForm.pushType === 3"
        >
          <el-upload
            class="img-list-uploader"
            action=""
            list-type="picture-card"
            :auto-upload="false"
            :show-file-list="true"
            :on-change="handleChange2"
            :file-list="pushForm.detailImageList"
          >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
              <img
              v-if="file.url"
              :src="file.url"
              class="imgbox"
              />
              <div
                @click.stop.prevent
                v-if="file.url"
                class="deletewrap"
              >
                <i class="el-icon-delete" @click="handleRemove2(file)"></i>
              </div>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上傳jpg/png文件，且不超過2MB，圖片比例 1:1
            </div>
          </el-upload>
        </el-form-item>

        <el-form-item
          label="通知内容"
          prop="content"
          v-if="pushForm.pushType === 3"
        >
          <div class="push-editorbox">
            <editor
              @getEditorHtml="getEditorHtml"
              :htmlData="pushForm.content"
            ></editor>
          </div>
        </el-form-item>

        <el-form-item label="是否限定會員" prop="isLimit" v-if="isPushNotification">
          <el-radio-group v-model="pushForm.isLimit">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>

          <div v-if="pushForm.isLimit === 1" class="inlineblock">
            <el-input disabled v-model="pushForm.fileName"></el-input>
            <el-upload
              class="upload-demo"
              action=""
              :auto-upload="false"
              :show-file-list="false"
              :on-change="handleChange3"
              accept=".xls,.xlsx"
            >
              <el-button type="primary" class="upbtn">上傳會員列表</el-button>
            </el-upload>

            <el-button type="primary" @click="downTemplate">下載模板</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>

    <div class="btnwrap">
      <div class="btnlist">
        <el-button type="primary" @click="onSubmit" :disabled="disabledSubmit">提交</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </div>

    <el-dialog title="選擇鏈接" :visible.sync="linkVisible" width="40%">
      <div class="linkSearchBox">
        <el-input v-model="linkSearch"></el-input>
        <el-button type="primary" @click="onSearch">搜索</el-button>
      </div>
      <el-table :data="linkVisibleData">
        <el-table-column property="value" label="名稱"></el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button @click="selectLink(scope.row)" type="primary" plain>
              選擇
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          :hide-on-single-page="isSinglePage"
          layout="prev, pager, next,jumper"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="tableDataTotal"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 引入富文本
import editor from "@/components/Editor.vue";
import * as api from "./api";
import { API } from "@/api/api.js"
import { tableDataPagination,checkURL } from "@/utils/common.js";
export default {
  components: {
    editor,
  },
  data() {
    var validateLink = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("請選擇跳轉鏈接"));
      } else {
        if (
          this.pushForm.pushType !== "" &&
          this.pushForm.pushType !== 3 &&
          this.pushForm.pushType !== 5 &&
          this.pushForm.linkName === ""
        ) {
          callback(new Error("請選擇跳轉鏈接"));
        } else if (
          this.pushForm.pushType !== "" &&
          this.pushForm.pushType === 5 &&
          this.pushForm.targetValue === ""
        ) {
          callback(new Error("請輸入跳轉鏈接"));
        }else if (
            this.pushForm.pushType !== "" &&
            this.pushForm.pushType === 5 &&
            this.pushForm.targetValue !== "" && !checkURL(this.pushForm.targetValue)
        ) {
            callback(new Error("請輸入正確的鏈接"));
        }
        callback();
      }
    };
    var validateisLimit = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("請選擇是否限定會員"));
      } else {
        if (this.pushForm.isLimit === 1 && this.pushForm.fileName === "") {
          callback(new Error("請上傳文件"));
        }
        callback();
      }
    };
    var validatedetailImageUrls= (rule, value, callback) => { {
        if (this.pushForm.detailImageUrls.length <= 0) {
          callback(new Error("請上傳圖片"));
        }
        callback();
      }
    }
    var validateStartDateTime= (rule, value, callback) => { {
        if (this.pushForm.startDateValue=="") {
          callback(new Error("請選擇日期"));
        }
        if (this.pushForm.startTimeValue=="") {
          callback(new Error("請選擇時間"));
        }
        callback();
      }
    }
    return {
      loading: false,
      isPushNotification:true,
      pushForm: {
        id: "",
        title: "",
        subTitle: "",
        startDateValue: "",
        startTimeValue: "",
        pushType: "",
        linkName: "",
        targetValue: "",
        listImageUrl: "",
        detailImageUrls: [],
        detailImageList:[],
        content: "",
        isLimit: "",
        fileName: "",
        couponDirectPush:false,
        limitMemberOutDto: {
          fileName: "",
          totalNum: 0,
          limitMemberMobileList: [],
        },
        refLimitMemberOutDto: ""
      },
      limitMemberOutDtoBackup:{
        fileName: "",
        totalNum: 0,
        limitMemberMobileList: [],
      },
      rules: {
        title: [{ required: true, message: "請輸入標題", trigger: "blur" }],
        subTitle: [
          { required: true, message: "請輸入標簽", trigger: "blur" },
        ],
        startDateValue: [
          { required: true, message: "請選擇推送日期和時間", trigger: "blur" },
           { validator: validateStartDateTime, trigger: ['blur','change'] },
        ],
        pushType: [
          { required: true, message: "請選擇跳轉鏈接", trigger: "blur" },
          { validator: validateLink, trigger: ['blur','change']},
        ],
        listImageUrl: [
          {
            required: true,
            message: "請上傳圖片",
            trigger: ["change", "blur"],
          },
        ],
        detailImageUrls: [
          {
            required: true,
            message: "請上傳圖片",
            trigger: ["change", "blur"],
          },
          { validator: validatedetailImageUrls, trigger: "change" },
        ],
        content: [
          { required: true, message: "請輸入通知内容", trigger: "blur" },
        ],
        isLimit: [
          { required: true, message: "請選擇是否限定會員", trigger: "blur" },
          { validator: validateisLimit, trigger: "change" },
        ],
      },
      timeOptions:[{value:"00:00:00",isdisabled:false},
        {value:"01:00:00",isdisabled:false},
        {value:"02:00:00",isdisabled:false},
        {value:"03:00:00",isdisabled:false},
        {value:"04:00:00",isdisabled:false},
        {value:"05:00:00",isdisabled:false},
        {value:"06:00:00",isdisabled:false},
        {value:"07:00:00",isdisabled:false},
        {value:"08:00:00",isdisabled:false},
        {value:"09:00:00",isdisabled:false},
        {value:"10:00:00",isdisabled:false},
        {value:"11:00:00",isdisabled:false},
        {value:"12:00:00",isdisabled:false},
        {value:"13:00:00",isdisabled:false},
        {value:"14:00:00",isdisabled:false},
        {value:"15:00:00",isdisabled:false},
        {value:"16:00:00",isdisabled:false},
        {value:"17:00:00",isdisabled:false},
        {value:"18:00:00",isdisabled:false},
        {value:"19:00:00",isdisabled:false},
        {value:"20:00:00",isdisabled:false},
        {value:"21:00:00",isdisabled:false},
        {value:"22:00:00",isdisabled:false},
        {value:"23:00:00",isdisabled:false},
      ],
      timeArr:[],
      pickerOptions: {
        disabledDate(time) {
          //此条为设置禁止用户选择今天之前的日期，包含今天。
          // return time.getTime() <= (Date.now());
          //此条为设置禁止用户选择今天之前的日期，不包含今天。
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      linkSearch: "",
      linkVisibleData: [],
      tableDataTotal: 0,
      linkVisible: false,
      isSinglePage: false,
      currentPage: 1,
      pageSize: 10,
      allRestauranList: [],
      allNewsList: [],
      allCouponList: [],
      pickerTimeOptions:{
        selectableRange: '00:00:00 - 23:59:00'
      },
      disabledSubmit:false
    };
  },
  computed:{
    
  },
  async created() {
    this.timeArr=this.timeOptions;
    await this.getSelectList(1);
    await this.getSelectList(2);
    await this.getSelectList(3);
    let queryObj = this.$route.query || {};
    if (queryObj.type && queryObj.type === "edit" && queryObj.id) {
      this.getPushDetails(queryObj.id);
    } else {
      this.loading = false;
    }
  },
  methods: {
    // 獲取選擇列表
    async getSelectList(type) {
      this.loading = true;
      await api.getSelectData({ type }).then(response => {
        if (type === 1) {
          this.allRestauranList = response.data;
        } else if (type === 2) {
          this.allNewsList = response.data;
        }else if (type === 3) {
          this.allCouponList = response.data;
        }
      });
    },
    changeCouponDirect(){
      if(this.pushForm.couponDirectPush){
        this.isPushNotification = false
        this.pushForm.pushType = 4
        this.pushForm.isLimit = 1
      }else{
        this.isPushNotification = true
      }
    },
    getPushDetails(id) {
      api.getPushDetails(id).then(res => {
        this.pushForm = Object.assign({}, this.pushForm, res.data);
        if([1,2,4].indexOf(this.pushForm.pushType)!==-1){
          this.pushForm.linkName = this.filterLinkName(Number(this.pushForm.targetValue));
        }
        let timeArr=this.pushForm.startTime.split(" ");
        this.pushForm.startDateValue=timeArr[0];
        this.pushForm.startTimeValue=timeArr[1];
        this.pushForm.fileName=this.pushForm.limitMemberOutDto.fileName;
        this.pushForm.detailImageUrls=[];
        this.isPushNotification = !this.pushForm.couponDirectPush
        res.data.detailImageUrls.forEach(item=>{
          if(item){
            this.pushForm.detailImageList.push({
              name:item,
              url:item
            })
          }
        })
        this.loading = false;
      });
    },
    // 獲取linkName
    filterLinkName(linkId){
      let nameItem=[];
      if(this.pushForm.pushType===1){
        nameItem=this.allRestauranList.filter(item=>linkId===item.key);
      }else if(this.pushForm.pushType===2){
        nameItem=this.allNewsList.filter(item=>linkId===item.key);
      }else{
        nameItem=this.allCouponList.filter(item=>linkId===item.key);
      }
      return nameItem.length>=1? nameItem[0].value:"";
    },
    // changeDate
    changeDate(value){
      this.pushForm.startTimeValue="";
      // this.getTimeArr();
      this.setMinTime();
    },
    setMinTime(){
      const time = new Date();
      let year = time.getFullYear();//获取年
      let month = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : '0' + Number(time.getMonth() + 1);//0开始  0-11 月
      let day = time.getDate() > 9 ? time.getDate() : '0' + time.getDate();//日
      let hours = time.getHours() > 9 ? time.getHours() : '0' + time.getHours();//时
      let mins = time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes();//分
      if(`${year}-${month}-${day}`==this.pushForm.startDateValue){
        this.pickerTimeOptions.selectableRange=`${hours}:${mins}:00 - 23:59:00`
      }else{
        this.pickerTimeOptions.selectableRange='00:00:00 - 23:59:00'
      }
    },
    getTimeArr(){
      const time = new Date();
      let year = time.getFullYear();//获取年
      let month = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : '0' + Number(time.getMonth() + 1);//0开始  0-11 月
      let day = time.getDate() > 9 ? time.getDate() : '0' + time.getDate();//日
      let hours = time.getHours() > 9 ? time.getHours() : '0' + time.getHours();//时
      if(`${year}-${month}-${day}`==this.pushForm.startDateValue){
        this.timeArr=this.timeOptions.map(item=>{
          let newItem={...item};//需要解構出來，否則會改變原數組
          if(item.value.substr(0,2)<=hours){
            newItem.isdisabled=true;
          }
          return newItem;
        })
      }else{
        this.timeArr=this.timeOptions
      }
    },
    // 上傳圖片
    async handleChange(file, fileList) {
      if (!await this.beforeUpload(file.raw)) return;
      this.disabledSubmit=true;
      let params = new FormData();
      params.append("file", file.raw);
      await api.uploadImage(params).then(res => {
        if (res.systemCode === 200) {
          this.pushForm.listImageUrl = res.data;
          this.$message.success("圖片上傳成功");
        }
      });
      this.disabledSubmit=false;
      this.$refs.pushForm.validateField("listImageUrl");
    },
    handleRemove() {
      this.pushForm.listImageUrl = "";
      this.$refs.pushForm.validateField("listImageUrl");
    },
    // 上傳詳情圖片
    async handleChange2(file, fileList) {
      if (!this.beforeUpload(file.raw)) {
        this.pushForm.detailImageList=fileList.filter(item=>item.uid!==file.uid);
        return;
      }
      let params = new FormData();
      params.append("file", file.raw);
      await api.uploadImage(params).then(res => {
        if (res.systemCode === 200) {
          file.url=res.data;
          this.pushForm.detailImageList=fileList;
          this.pushForm.detailImageUrls.push(res.data);
          this.$message.success("圖片上傳成功");
        }else{
          this.$message.error("圖片上傳失敗");
          this.pushForm.detailImageList.pop();
        }
      }).catch(err=>{
        this.pushForm.detailImageList.pop();
      });
      this.$refs.pushForm.validateField("detailImageUrls");
    },
    handleRemove2(file) {
      this.pushForm.detailImageList=this.pushForm.detailImageList.filter(item=>item.url!==file.url)
      this.pushForm.detailImageUrls=this.pushForm.detailImageUrls.filter(item=>item!==file.url);
      this.$refs.pushForm.validateField("detailImageUrls");
    },
    // 上傳會員列表
    async handleChange3(file, fileList) {
      if (!this.beforeFileUpload(file.raw)) return;
      let params = new FormData();
      params.append("file", file.raw);
      await api.uploadFile(params).then(res => {
        if (res.systemCode === 200) {
          this.pushForm.refLimitMemberOutDto = res.data.cacheKey;
          this.pushForm.fileName = res.data.fileName;
          this.$message.success("文件上傳成功");
        }
      });
      this.$refs.pushForm.validateField("isLimit");
    },
    // 限制格式和大小
    beforeFileUpload(file) {
      const isType = file.type === "application/vnd.ms-excel";
      const isTypeComputer =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const fileType = isType || isTypeComputer;
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!fileType) {
        this.$message.error("上传文件只能是xls/xlsx格式！");
      }
      if (!isLt2M) {
        this.$message.error("上傳圖片大小不能超過 2MB!");
      }
      return fileType && isLt2M;
    },
    // 限制格式和大小
    beforeUpload(file) {
      const acceptArr = ["image/jpeg", "image/png"];
      const isJPG = acceptArr.indexOf(file.type) !== -1;
      const isLt2M = file.size / 1024 / 1024 < 2;
      // let whIsTrue= false;
      // 获取宽高
      // let {imgHeight,imgWidth}=await getImageInfo(file);
      // whIsTrue= imgHeight/imgWidth===1 ? true:false; //(1:1=>1)
      // if (!whIsTrue) {
      //   this.$message.error("上傳圖片比例錯誤!");
      // }
      if (!isJPG) {
        this.$message.error("上傳圖片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上傳圖片大小不能超過 2MB!");
      }
      return isJPG && isLt2M;
    },
    // getEditorHtml
    getEditorHtml(data) {
      this.pushForm.content = data.html;
      this.$refs.pushForm.validateField("content");
    },
    // selectLink
    openLinkVisible() {
      this.currentPage = 1;
      this.linkSearch = "";
      if (this.pushForm.pushType === 1) {
        this.getPageRes(this.allRestauranList);
      } else if (this.pushForm.pushType === 2) {
        this.getPageRes(this.allNewsList);
      } else if (this.pushForm.pushType === 4) {
        this.getPageRes(this.allCouponList);
      }
      this.linkVisible = true;
    },
    // changePushType
    changePushType(val){
      this.pushForm.targetValue = "";
      this.pushForm.linkName = "";
    },
    selectLink(item) {
      this.pushForm.targetValue = item.key;
      this.pushForm.linkName = item.value;
      this.linkVisible = false;
    },
    // 翻頁
    handleCurrentChange(val) {
      this.currentPage = val;
      if (this.pushForm.pushType === 1) {
        this.getPageRes(this.allRestauranList);
      } else if (this.pushForm.pushType === 2) {
        this.getPageRes(this.allNewsList);
      }else{
        this.getPageRes(this.allCouponList);
      }
    },
    // 查詢
    onSearch() {
      this.currentPage = 1;
      if (this.pushForm.pushType === 1) {
        this.getPageRes(this.allRestauranList);
      } else if (this.pushForm.pushType === 2) {
        this.getPageRes(this.allNewsList);
      }else{
        this.getPageRes(this.allCouponList);
      }
    },
    // 處理表格數據
    getPageRes(allData) {
      if(allData.length<=0){
        this.$message.error("數據錯誤,請稍後重試");
        return false;
      }
      let res = tableDataPagination(
        allData,
        this.currentPage,
        this.pageSize,
        this.linkSearch
      );
      this.linkVisibleData = res.dataList;
      this.tableDataTotal = res.dataTotal;
    },
    // 提交
    onSubmit() {
      this.$refs["pushForm"].validate(valid => {
        if (valid) {
          this.loading=true;
          let params = {
            title: this.pushForm.title,
            subTitle: this.pushForm.subTitle,
            startTime: this.pushForm.startDateValue&&this.pushForm.startTimeValue?(this.pushForm.startDateValue + " " +this.pushForm.startTimeValue):null,
            pushType: this.pushForm.pushType,
            targetValue: String(this.pushForm.targetValue),
            listImageUrl: this.pushForm.listImageUrl,
            detailImageUrls: this.pushForm.detailImageUrls,
            content: this.pushForm.content,
            isLimit: this.pushForm.isLimit,
            limitMemberOutDto:null,
            refLimitMemberOutDto:this.pushForm.refLimitMemberOutDto,
            couponDirectPush:this.pushForm.couponDirectPush
          };
          if (this.pushForm.id) {
            params.id = this.pushForm.id;
          }
          if(!this.pushForm.isLimit){
            params.refLimitMemberOutDto = "";
          }
          api.pushEdit(params).then(res => {
            if (res.systemCode == 200) {
              this.$message.success("提交成功");
              this.$router.push({ path: "/cms/pushlist" });
            } else {
              // this.$message.error(res.message);
            }
            this.loading=false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 下載模板
    downTemplate(){
      window.open(API.limitMemberTemplate,"_blank")
    },
    // 取消
    cancel() {
      this.$router.push({ path: "/cms/pushlist" });
    },
  },
};
</script>

<style lang="scss" scoped>
.pushedit {
  .formwrap {
    padding-top: 20px;
    padding-left: 20px;
    width: 500px;
    .inlineblock {
      width: 500px;
      .upbtn{
        margin-right: 10px;
      }
      .el-input {
        width: 50%;
        margin-right: 10px;
      }
      .upload-demo {
        display: inline-block;
      }
    }
  }
  .linkSearchBox {
    width: 500px;
    margin-bottom: 10px;
    .el-input {
      width: 250px;
      margin-right: 10px;
    }
  }
  .startDateTimeBox{
    width: 500px;
  }
}
</style>
<style lang="scss">
.push-editorbox {
  width: 680px;
  height: 400px;
  .myeditor {
    height: 400px;
    .ql-container {
      height: 85%;
      background: #000;
      color: #fff;
    }
  }
}
</style>
